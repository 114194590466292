
import { createRouter, createWebHistory } from 'vue-router';

const router = createRouter({
  routes: [
    {
      path: '/',
      redirect: '/HomeComponent',
    },
    {
      path: '/HomeComponent',
      redirect:'/home',
      //路由到的地址(自定义)
      name: 'HomeComponent',
      //组件名称
      component: () => import('../views/HomeComponent.vue'),
      children: [{
        path: '/home',
        //路由到的地址(自定义)
        name: 'home',
        //组件名称
        component: () => import('../views/index.vue')
      },{
        path: '/product',
        name: 'product',
        component: () => import('../views/product.vue')
      },{
        path: '/solution',
        name: 'solution',
        component: () => import('../views/solution.vue')
      },{
        path: '/join',
        name: 'join',
        component: () => import('../views/join.vue')
      },{
        path: '/about',
        name: 'about',
        component: () => import('../views/about.vue')
      }]
    },

  ],
  history: createWebHistory()
})
export default router;